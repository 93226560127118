import * as React from 'react'
import { Helmet } from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Slider from 'react-slick'

const Gallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout pageTitle="Spiked">
      <Helmet>
        <meta
          name="description"
          content="Images from the production of Spiked Film"
        />
        <title>Gallery | Spiked Film</title>
        <body className="bg-img gallery-bg-img" />
      </Helmet>
      <Slider {...settings}>
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/01.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/02.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/03.jpg"
        />
        <StaticImage
          alt="Photo of Aidan Quinn On Set of Spiked film"
          src="../images/gallery/04.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/05.jpg"
        />
        <StaticImage
          alt="Photo of stack of newspapers from the Spiked film"
          src="../images/gallery/06.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/07.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/08.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/09.jpg"
        />
        <StaticImage
          alt="Photo of Joe Soldwedel"
          src="../images/gallery/10.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/11.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/12.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/13.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/14.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/15.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/16.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/17.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/18.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/19.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/20.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/21.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/22.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/23.jpg"
        />
        <StaticImage
          alt="Photo of Spiked Film Crew on set filming"
          src="../images/gallery/24.jpg"
        />
      </Slider>
    </Layout>
  )
}

export default Gallery
